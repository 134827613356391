import React from 'react'
import '../styles/Bottom.css'

function Bottom() {
  return (
    <div className='main_bottom'>
        <div>a</div> 
        <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>
       <h1>hola</h1>   
    </div>
  )
}

export default Bottom