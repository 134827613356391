import React from 'react'
import '../styles/Footer.css'

function Footer() {
  return (
    <div className='main_footer'>
      <h1>footer</h1>
    </div>
  )
}

export default Footer